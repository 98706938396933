@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
}

.react-datepicker-wrapper {
  input {
    width: 100%;
    height: 2rem;
    border-radius: 0.375rem;
    border: 2px solid rgb(93 97 105 / var(--tw-border-opacity));
    background-color: rgb(48 54 59 / var(--tw-bg-opacity));
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.react-datepicker {
  border: 2px solid rgb(93 97 105 / var(--tw-border-opacity)) !important;
}

.react-datepicker,
.react-datepicker__header {
  background-color: #30363b !important;
}

.react-datepicker__day-name,
.react-datepicker__week,
.react-datepicker__day,
.react-datepicker__current-month {
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ea3323 !important;
}

.react-datepicker__day:hover {
  background-color: #ee5b4f !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #202226;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #7a7f89;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7a7f89;
}